import {Box, Button, Divider, Grid, Stack, useTheme} from '@mui/material';
import {
    FormikForm,
    IMultiselectOption,
    IPackageSizesOutput,
    ITeamListingOutput,
    MemberOperationType,
    Translation,
    createJobTeamListingSelector,
    currentlySelectedModalTypeSelector,
    IJobLocationInput,
    isModalOpenSelector,
    openModal,
    IJobRecipientInput,
    ChevronRight,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    CreateJobWizardSteps,
    IGeneralData,
    getParameters,
    setCurrentStep,
    setJobGeneralData,
    setPackageDimensions,
    setJobSenderData,
    setJobReceiverData,
    setJobRecipientData,
    setJobAdditionalData,
    IAdditionalData,
    resetToInitialCreateJobViewState,
} from '../../../../store/reducers/createJobViewSlice';
import {
    createJobGeneralDataSelector,
    isImmediateOrderSelector,
    isProcessAtSelector,
} from '../../../../store/selectors/createJobViewSelectors';
import BoxSizesModal from './BoxSizesModal';
import JobCompletionSection from './JobCompletionSection';
import createJobTeamSelectorFormConfig from './createJobTeamSelectorFormConfig';
import packageSizeFormConfig from './packageSizeFormConfig';
import {useLocation} from 'react-router';
import {jobActiveCurrentlySelectedTeamSelector} from '../../../../store/selectors/jobListingSelector';
import {setActiveJobTeamFilter} from 'src/store/reducers/jobListingSlice';

export const NULL_PACKAGE_SIZES: IPackageSizesOutput = {
    s: false,
    m: false,
    l: false,
};

interface ICreateJobGeneralDataProps {}

const CreateJobGeneralData: React.FC<ICreateJobGeneralDataProps> = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        generalData: IGeneralData | null = useSelector(createJobGeneralDataSelector),
        currentlySelectedTeamId: string | undefined = useSelector(jobActiveCurrentlySelectedTeamSelector),
        // insuranceParameters = useSelector(insuranceParametersSelector),
        [selectedTeamId, setSelectedTeamId] = useState<string | null>(currentlySelectedTeamId || null),
        [selectedPackageSize, setSelectedPackageSize] = useState<IPackageSizesOutput>(NULL_PACKAGE_SIZES),
        // [isOrderInsured, setIsOrderInsured] = useState<boolean>(false),
        // [insuranceValue, setInsuranceValue] = useState<number>(200),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        isImmediateOrder: boolean = useSelector(isImmediateOrderSelector),
        processAtDate: string | null = useSelector(isProcessAtSelector);

    const [shouldUpdateProcessAtControl, setShouldUpdateProcessAtControl] = useState<boolean>(false);

    const location = useLocation();

    const teams = useSelector(createJobTeamListingSelector);
    const teamListSelect = (): IMultiselectOption[] => {
        return teams?.map((team: ITeamListingOutput) => ({
            value: team.id,
            label: team.name,
        }));
    };

    const checkIsInsuranceDateInFuture = () => {
        return !!processAtDate && new Date(processAtDate) > new Date();
    };

    useEffect(() => {
        dispatch(getParameters());
        if (location?.state?.job) {
            const job = location.state.job,
                generalData: IGeneralData = {
                    teamId: job.team.id,
                    packageSize: job.packageSize,
                    isImmediateOrder: job.isImmediateOrder,
                    insuranceValue: job.insuranceValue
                        ? {
                              amount: job.insuranceValue.amount,
                              currency: job.insuranceValue.currency.name,
                          }
                        : null,
                    processAt: job.isImmediateOrder ? null : job.processAt,
                },
                senderData: IJobLocationInput = {
                    locationId: job.fromLocation.locationId,
                    companyName: job.fromLocation?.companyName || null,
                    phone: job.fromLocation?.phone || null,
                    addressLine: job.fromLocation?.addressLine || null,
                    flatNumber: job.fromLocation?.flatNumber || null,
                    point: job?.fromLocation?.point
                        ? {
                              latitude: job.fromLocation.point.latitude,
                              longitude: job.fromLocation.point.longitude,
                          }
                        : null,
                    street: job?.fromLocation.street,
                    houseNumber: job?.fromLocation.houseNumber,
                    zip: job?.fromLocation.zip,
                    city: job?.fromLocation.city,
                    country: job?.fromLocation.country,
                },
                receiverData: IJobLocationInput = {
                    locationId: job.toLocation.locationId,
                    companyName: job.toLocation?.companyName || null,
                    addressLine: job.toLocation?.addressLine || null,
                    phone: job.toLocation?.phone || null,
                    flatNumber: job.toLocation?.flatNumber || null,
                    point: job.toLocation?.point
                        ? {
                              latitude: job.toLocation.point.latitude,
                              longitude: job.toLocation.point.longitude,
                          }
                        : null,
                    street: job?.toLocation.street,
                    houseNumber: job?.toLocation.houseNumber,
                    zip: job?.toLocation.zip,
                    city: job?.toLocation.city,
                    country: job?.toLocation.country,
                },
                recipientData: IJobRecipientInput = {
                    teamMemberId: job.recipient.teamMemberId,
                    firstName: job.recipient?.firstName || null,
                    lastName: job.recipient?.lastName || null,
                    phone: job.recipient?.phone || null,
                },
                additionalData: IAdditionalData = {
                    orderNumber: job?.orderNumber || null,
                    description: job?.description || null,
                };

            if (job?.team?.id) {
                setSelectedTeamId(job.team.id);
            }

            dispatch(setJobGeneralData(generalData));
            dispatch(setJobSenderData(senderData));
            dispatch(setJobReceiverData(receiverData));
            dispatch(setJobRecipientData(recipientData));
            dispatch(setJobAdditionalData(additionalData));
        }
    }, [location]);

    {
        /* TODO PT: Insurance parts hidden till moment when PaliPali will get insurance company agreement */
    }
    // useEffect(() => {
    //     if (insuranceParameters) {
    //         setInsuranceValue(insuranceParameters.minValue);
    //     }
    // }, [insuranceParameters]);

    useEffect(() => {
        if (generalData && generalData.packageSize) {
            setSelectedTeamId(generalData.teamId);
            setSelectedPackageSize({
                ...NULL_PACKAGE_SIZES,
                [generalData.packageSize]: true,
            });
        }

        if (generalData && generalData.processAt) {
            const isInsuranceDateInFuture = checkIsInsuranceDateInFuture();
            if (!isInsuranceDateInFuture) {
                setShouldUpdateProcessAtControl(true);
            }
        }
    }, [generalData]);

    useEffect(() => {
        const isInsuranceDateInFuture = checkIsInsuranceDateInFuture();
        setShouldUpdateProcessAtControl(!isInsuranceDateInFuture);
    }, [processAtDate]);

    useEffect(() => {
        teamListSelect();
    }, [teams]);

    const isBoxSizesModalOpen = isModalOpen && modalType === MemberOperationType.NEW_ORDER_DIMENSIONS;
    useEffect(() => {
        if (!isBoxSizesModalOpen) {
            dispatch(setPackageDimensions(null));
        }
    }, [isBoxSizesModalOpen]);
    const selectTeam = (teamId: string) => {
        if (teamId !== selectedTeamId) {
            setSelectedTeamId(teamId);
            dispatch(setActiveJobTeamFilter(teamId));
            dispatch(resetToInitialCreateJobViewState());
        }
    };

    const openBoxSizesModal = () => {
        dispatch(openModal(MemberOperationType.NEW_ORDER_DIMENSIONS));
    };

    const handleBoxSizeSelection = (value: 's' | 'm' | 'l') => {
        const updatedSizes: IPackageSizesOutput = {
            s: false,
            m: false,
            l: false,
        };

        setSelectedPackageSize({
            ...updatedSizes,
            [value]: true,
        });
    };

    const getSelectedPackageSize = (obj: IPackageSizesOutput): string => {
        for (const key in obj) {
            if (obj[key as keyof IPackageSizesOutput]) {
                return key;
            }
        }
        return 's';
    };

    const isValid = (): boolean => {
        const isInsuranceDateInFuture = checkIsInsuranceDateInFuture();

        return (
            !!selectedTeamId &&
            selectedPackageSize &&
            Object.values(selectedPackageSize).some((value) => value) &&
            // (!isOrderInsured || (isOrderInsured && !!insuranceValue)) &&
            (isImmediateOrder || (!isImmediateOrder && isInsuranceDateInFuture))
        );
    };

    const hasTrueValue = (obj: IPackageSizesOutput): boolean => {
        for (const key in obj) {
            if (obj[key as keyof IPackageSizesOutput]) {
                return true;
            }
        }
        return false;
    };

    const handleSaveData = () => {
        const isInsuranceDateInFuture = checkIsInsuranceDateInFuture() && !isImmediateOrder;

        if (!isInsuranceDateInFuture && !isImmediateOrder) {
            setShouldUpdateProcessAtControl(true);
        }

        if (selectedTeamId && selectedPackageSize && hasTrueValue(selectedPackageSize) && (isInsuranceDateInFuture || isImmediateOrder)) {
            const generalData: IGeneralData = {
                teamId: selectedTeamId,
                packageSize: getSelectedPackageSize(selectedPackageSize) as 's' | 'm' | 'l',
                // insuranceValue: isOrderInsured && insuranceValue ? {amount: String(insuranceValue * 100), currency: 'PLN'} : null,
                insuranceValue: null,
                isImmediateOrder: isImmediateOrder,
                processAt: isImmediateOrder ? null : processAtDate,
            };
            dispatch(setJobGeneralData(generalData));
            dispatch(setCurrentStep(CreateJobWizardSteps.SENDER_DATA));
        }
    };

    return (
        <Stack>
            <Box
                sx={{
                    padding: '2rem',
                    width: '100%',
                    maxWidth: '50rem',
                }}>
                <FormikForm
                    fields={createJobTeamSelectorFormConfig(teamListSelect())}
                    formId="active-job-list-filters"
                    formClassName="active-job-list-filters-form"
                    initialValues={{team: generalData?.teamId || ''}}
                    updatedValues={{team: selectedTeamId}}
                    customEventChange={(formControlName, value) => {
                        selectTeam(value);
                    }}
                    theme={theme}
                    onSubmit={() => null}
                />

                <Box>
                    <FormikForm
                        fields={packageSizeFormConfig()}
                        formId="job-general-data"
                        initialValues={NULL_PACKAGE_SIZES}
                        updatedValues={selectedPackageSize}
                        isSinglePackageSelect={true}
                        onPackageDimensionChange={handleBoxSizeSelection}
                        theme={theme}
                        packageControlLabel={'jobs.createJob.generalData.orderCategory'}
                        onSubmit={() => null}
                    />

                    <Button className="category-modal-button" onClick={() => openBoxSizesModal()}>
                        <Translation text="jobs.createJob.generalData.orderCategoryUnknown" />
                    </Button>
                </Box>
                {/* TODO PT: Hidden till moment when PaliPali will get insurance company agreement */}
                {/*
                    <InsuranceQuantitySection
                        insuranceValue={insuranceValue}
                        setInsuranceValue={setInsuranceValue}
                        isOrderInsured={isOrderInsured}
                        setIsOrderInsured={setIsOrderInsured}
                    /> */}
                <JobCompletionSection shouldUpdateValidation={shouldUpdateProcessAtControl} />
                {isModalOpen && modalType === MemberOperationType.NEW_ORDER_DIMENSIONS && (
                    <BoxSizesModal onSelectDimensions={setSelectedPackageSize} />
                )}
            </Box>
            <Divider />
            <Grid className={'navigation-buttons-container'}>
                <Button
                    key="next-button"
                    color="primary"
                    variant="contained"
                    disabled={!isValid()}
                    onClick={() => handleSaveData()}
                    className="navigation-button next-button"
                    endIcon={<ChevronRight />}>
                    <Translation text="jobs.createJob.generalData.nextButton" />
                </Button>
            </Grid>
        </Stack>
    );
};

export default CreateJobGeneralData;
