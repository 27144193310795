import {Button, Card, CardActions, CardContent, Divider, Unstable_Grid2 as Grid, Stack, Typography, useTheme} from '@mui/material';
import {ChevronLeftIcon, FormikForm, Loader, Translation} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {isTeamLoadingSelector, teamAddressFromTaxIdSelector} from 'src/store/selectors/teamViewSelectors';
import {createTeam, getAddressFromTaxId, returnToInitialTeamState} from '../../../store/reducers/teamViewSlice';
import {teamCreateFormConfig} from './teamCreateFormConfig';

interface ITeamCreateProps {}

export type ITeamCreateFormValues = {
    name: string;
    companyName: string;
    taxIdentifier: string;
    street: string;
    houseNumber: string;
    flatNumber: string;
    clearingEmail: string;
    city: string;
    zip: string;
};

const CreateTeam: React.FC<ITeamCreateProps> = () => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        theme = useTheme(),
        [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false),
        [validTaxIdValue, setValidNipValue] = useState<string | null>(null),
        isLoading = useSelector(isTeamLoadingSelector),
        addressFromTaxId = useSelector(teamAddressFromTaxIdSelector),
        initialFormValues: ITeamCreateFormValues = {
            name: '',
            companyName: '',
            taxIdentifier: '',
            street: '',
            houseNumber: '',
            flatNumber: '',
            clearingEmail: '',
            city: '',
            zip: '',
        },
        [formikCurrentValues, setFormikCurrentValues] = useState<ITeamCreateFormValues>(initialFormValues),
        [updatedValues, setUpdatedValues] = useState<ITeamCreateFormValues>(initialFormValues);

    useEffect(() => {
        return () => {
            dispatch(returnToInitialTeamState());
        };
    }, []);

    useEffect(() => {
        if (validTaxIdValue && !isLoading) {
            dispatch(getAddressFromTaxId(validTaxIdValue));
        }
    }, [validTaxIdValue]);

    useEffect(() => {
        if (addressFromTaxId) {
            setUpdatedValues({
                ...formikCurrentValues,
                taxIdentifier: addressFromTaxId.taxIdentifier ? addressFromTaxId.taxIdentifier : '',
                companyName: addressFromTaxId.companyName ? addressFromTaxId.companyName : '',
                street: addressFromTaxId.street ? addressFromTaxId.street : '',
                houseNumber: addressFromTaxId.houseNumber ? addressFromTaxId.houseNumber : '',
                flatNumber: addressFromTaxId.flatNumber ? addressFromTaxId.flatNumber : '',
                city: addressFromTaxId.city ? addressFromTaxId.city : '',
                zip: addressFromTaxId.zip ? addressFromTaxId.zip : '',
            });
        }
    }, [addressFromTaxId]);

    const performSubmitOperation = (values: ITeamCreateFormValues) => {
        const data = {
            name: values?.name,
            companyName: values?.companyName,
            taxIdentifier: values?.taxIdentifier,
            street: values?.street,
            clearingEmail: values?.clearingEmail,
            houseNumber: values?.houseNumber,
            flatNumber: values?.flatNumber === '' ? null : values?.flatNumber,
            city: values?.city,
            zip: values?.zip,
            countryId: null,
        };
        dispatch(createTeam(data));
    };

    return (
        <Stack>
            <div className="btn-back-wrapper">
                <Button onClick={() => navigate('/panel/teams/')} className="btn-action" startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.orderDetails.buttons.return" />
                </Button>
            </div>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="teams.newTeam" />
                    </Typography>
                </div>
            </div>
            <Grid container>
                <Grid lg={6} md={6}>
                    <Card className="custom-card">
                        <CardContent>
                            <FormikForm
                                fields={teamCreateFormConfig(setValidNipValue)}
                                formId="team-create-form"
                                initialValues={initialFormValues}
                                updatedValues={updatedValues}
                                theme={theme}
                                onFormikValuesChange={(values) => setFormikCurrentValues(values)}
                                submitAllowed={setIsSubmitAllowed}
                                onSubmit={performSubmitOperation}
                            />
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button variant="contained" color="primary" type="submit" form="team-create-form" disabled={!isSubmitAllowed}>
                                <Translation text={`teams.teamView.buttons.createTeam`} />
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Loader showLoader={isLoading} />
        </Stack>
    );
};

export default CreateTeam;
