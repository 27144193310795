import {Button, useTheme} from '@mui/material';
import {FormikForm, HorizontalCard, ICreateTeamInput, ITeamViewOutput, Translation} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getAddressFromTaxId, updateTeam} from '../../../../../store/reducers/teamViewSlice';
import {teamEditFormConfig} from './teamEditFormConfig';
import {useFormik} from 'formik';
import {isTeamLoadingSelector, teamAddressFromTaxIdSelector} from 'src/store/selectors/teamViewSelectors';

interface ITeamDetailsCardTabProps {
    teamView: ITeamViewOutput;
    editMode?: boolean;
}

const TeamDetailsCard: React.FC<ITeamDetailsCardTabProps> = ({teamView, editMode}) => {
    const dispatch = useDispatch(),
        {id} = useParams(),
        theme = useTheme(),
        [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false),
        [validTaxIdValue, setValidNipValue] = useState<string | null>(null),
        initialValues: ICreateTeamInput = {
            name: teamView.name ? teamView.name : '',
            companyName: teamView.companyName ? teamView.companyName : '',
            taxIdentifier: teamView.taxIdentifier ? teamView.taxIdentifier : '',
            street: teamView.street ? teamView.street : '',
            clearingEmail: teamView.clearingEmail ? teamView.clearingEmail : '',
            houseNumber: teamView.houseNumber ? teamView.houseNumber : '',
            flatNumber: teamView.flatNumber ? teamView.flatNumber : '',
            city: teamView.city ? teamView.city : '',
            zip: teamView.zip ? teamView.zip : '',
            countryId: teamView.countryId ? teamView.countryId : '',
        },
        isLoading = useSelector(isTeamLoadingSelector),
        addressFromTaxId = useSelector(teamAddressFromTaxIdSelector);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            const payload = {
                name: values?.name,
                companyName: values?.companyName,
                taxIdentifier: values?.taxIdentifier,
                street: values?.street,
                clearingEmail: values?.clearingEmail,
                houseNumber: values?.houseNumber,
                flatNumber: values?.flatNumber === '' ? null : values?.flatNumber,
                city: values?.city,
                zip: values?.zip,
            };
            dispatch(updateTeam(id, payload));
        },
    });
    useEffect(() => {
        if (validTaxIdValue && !isLoading) {
            dispatch(getAddressFromTaxId(validTaxIdValue));
        }
    }, [validTaxIdValue]);

    useEffect(() => {
        if (addressFromTaxId) {
            formik.setValues({
                ...formik.values,
                taxIdentifier: addressFromTaxId.taxIdentifier ? addressFromTaxId.taxIdentifier : '',
                companyName: addressFromTaxId.companyName ? addressFromTaxId.companyName : '',
                street: addressFromTaxId.street ? addressFromTaxId.street : '',
                houseNumber: addressFromTaxId.houseNumber ? addressFromTaxId.houseNumber : '',
                flatNumber: addressFromTaxId.flatNumber ? addressFromTaxId.flatNumber : '',
                city: addressFromTaxId.city ? addressFromTaxId.city : '',
                zip: addressFromTaxId.zip ? addressFromTaxId.zip : '',
            });
        }
    }, [addressFromTaxId]);

    return (
        <HorizontalCard
            heading={<Translation text="teams.teamView.title" />}
            content={
                teamView && (
                    <FormikForm
                        fields={teamEditFormConfig(setValidNipValue, !editMode)}
                        formId="team-edit-form"
                        submitAllowed={setIsSubmitAllowed}
                        customEventChange={(formControl: string, value: string) => {
                            formik.setFieldValue(formControl, value);
                        }}
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        shouldValidate={formik.dirty}
                        theme={theme}
                        onSubmit={formik.handleSubmit}
                    />
                )
            }
            actions={
                <Button className="btn btn-action" type="submit" form="team-edit-form" disabled={!isSubmitAllowed || !editMode}>
                    <Translation text={`teams.teamView.buttons.update`} />
                </Button>
            }
        />
    );
};

export default TeamDetailsCard;
