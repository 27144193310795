import {Box, Button, Divider, Stack, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {
    APP_THEME_DARK,
    CustomModalComponent,
    FormikForm,
    IAssignPackageSizeToDimensionsInput,
    IPackageSizesOutput,
    Loader,
    LoaderType,
    PackageTypes,
    Translation,
    accountThemeSelector,
    closeModal,
    packageParametersSelector,
} from 'palipali-panel-common-web';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import packageIcon from '../../../../../assets/images/package_preview_icon.png';
import packageIconDark from '../../../../../assets/images/package_preview_icon_dark.png';
import {assignPackageDimensions} from '../../../../../store/reducers/createJobViewSlice';
import {createJobPackageDimensions, isDimensionsModalLoadingSelector} from '../../../../../store/selectors/createJobViewSelectors';
import BoxSizeItem from '../../../../shared/BoxSizeSection/BoxSizeItem';
import boxSizesFormConfig from './boxSizesFormConfig';

export interface BoxSizesForm {
    length: number | undefined;
    height: number | undefined;
    width: number | undefined;
    weight: number | undefined;
}

interface IBoxSizesModalProps {
    onSelectDimensions: (value: IPackageSizesOutput) => void;
}

const BoxSizesModal: React.FC<IBoxSizesModalProps> = ({onSelectDimensions}) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        isLoading = useSelector(isDimensionsModalLoadingSelector),
        themeValue: string = useSelector(accountThemeSelector),
        isDarkTheme = themeValue === APP_THEME_DARK,
        initialValues: BoxSizesForm = {
            length: undefined,
            height: undefined,
            width: undefined,
            weight: undefined,
        },
        [formValues, setFormValues] = useState<BoxSizesForm>(initialValues),
        [isFormValid, setIsFormValid] = useState<boolean>(false),
        selectedPackageSize = useSelector(createJobPackageDimensions),
        [selectedDimensions, setSelectedDimensions] = useState<keyof IPackageSizesOutput | null>(null),
        [isDimensionExceeded, setIsDimensionExceeded] = useState<boolean>(false),
        packageParameters: PackageTypes = useSelector(packageParametersSelector);

    const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            handleSizeCalculationData(values);
        },
    });

    useEffect(() => {
        if (isFormValid) {
            if (timeoutRef) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                handleSizeCalculationData(formik.values);
            }, 750);
        }
    }, [isFormValid, formik.values]);

    useEffect(() => {
        if (selectedPackageSize) {
            const selectedPackage = getSelectedPackageKey(selectedPackageSize);
            if (selectedPackage) {
                setSelectedDimensions(selectedPackage);
                setIsDimensionExceeded(false);
            } else {
                setIsDimensionExceeded(true);
            }
        }
    }, [selectedPackageSize]);

    const handleSizeCalculationData = (values: BoxSizesForm) => {
        if (!values || !values.length || !values.height || !values.width || !values.weight) {
            return;
        }

        const dimensions: IAssignPackageSizeToDimensionsInput = {
            width: Number(values.width),
            height: Number(values.height),
            length: Number(values.length),
            weight: Number(values.weight),
        };
        dispatch(assignPackageDimensions(dimensions));
    };

    const getSelectedPackageKey = (obj: IPackageSizesOutput): keyof IPackageSizesOutput | null => {
        for (const key in obj) {
            if (obj[key as keyof IPackageSizesOutput]) {
                return key as keyof IPackageSizesOutput;
            }
        }

        return null;
    };

    const onChange = (value: string, field: string) => {
        const updatedState = {
            ...formValues,
            [field]: value,
        };
        setFormValues(updatedState);
    };

    const maxPossibleDimensions = packageParameters.l;
    return (
        <CustomModalComponent
            theme={theme}
            customClasses="box-dimensions-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="jobs.createJob.generalData.boxDimensionsModal.title" />
                </Typography>
            }
            content={
                <>
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                    <Stack spacing={5} sx={{position: 'relative'}}>
                        <Stack spacing={2}>
                            <FormikForm
                                fields={boxSizesFormConfig()}
                                formId="box-sizes-form-data"
                                initialValues={initialValues}
                                theme={theme}
                                submitAllowed={setIsFormValid}
                                customEventChange={(formControl: string, value: string) => {
                                    onChange(value, formControl);
                                    formik.setFieldValue(formControl, value);
                                }}
                                onSubmit={() => formik.submitForm()}
                            />
                        </Stack>
                        <Stack gap={'1.6rem'}>
                            <Box className={`package-size-preview ${isDarkTheme && 'dark-mode'}`}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 122,
                                        maxHeight: {xs: 122, md: 122},
                                    }}
                                    alt="Package size"
                                    src={isDarkTheme ? packageIconDark : packageIcon}
                                />
                                <Box className="package-size-label height-label" color="text.primary">
                                    <Typography className="label">{formValues?.height || '-'}</Typography>
                                    <Typography className="label">
                                        <Translation text={'jobs.createJob.generalData.boxDimensionsModal.formControls.cm'} />
                                    </Typography>
                                </Box>
                                <Box className="package-size-label length-label" color="text.primary">
                                    <Typography className="label">{formValues?.length || '-'}</Typography>
                                    <Typography className="label">
                                        <Translation text={'jobs.createJob.generalData.boxDimensionsModal.formControls.cm'} />
                                    </Typography>
                                </Box>
                                <Box className="package-size-label weight-label" color="text.primary">
                                    <Typography className="label">{formValues?.weight || '-'}</Typography>
                                    <Typography className="label">
                                        <Translation text={'jobs.createJob.generalData.boxDimensionsModal.formControls.kg'} />
                                    </Typography>
                                </Box>
                                <Box className="package-size-label width-label" color="text.primary">
                                    <Typography className="label">{formValues?.width || '-'}</Typography>
                                    <Typography className="label">
                                        <Translation text={'jobs.createJob.generalData.boxDimensionsModal.formControls.cm'} />
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1" color="text.primary" className="suggested-category-title">
                                    <Translation text={'jobs.createJob.generalData.boxDimensionsModal.suggestedCategory'} />
                                </Typography>
                                <Divider />
                                <Box className="suggested-category-details">
                                    {isDimensionExceeded ? (
                                        <Typography textAlign={'center'} variant="subtitle2" color="text.secondary">
                                            <Translation
                                                text={'jobs.createJob.generalData.boxDimensionsModal.notSuitableDimensions'}
                                                config={{
                                                    a: maxPossibleDimensions.a,
                                                    b: maxPossibleDimensions.b,
                                                    c: maxPossibleDimensions.c,
                                                    weight: maxPossibleDimensions.weight,
                                                }}
                                            />
                                        </Typography>
                                    ) : selectedDimensions ? (
                                        <BoxSizeItem type={selectedDimensions} selected={false} />
                                    ) : (
                                        <Typography className="modal-description">
                                            <Translation text={'jobs.createJob.generalData.boxDimensionsModal.checkDimensions'} />
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </>
            }
            footer={
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        if (!selectedPackageSize) {
                            return;
                        }
                        onSelectDimensions(selectedPackageSize);
                        dispatch(closeModal());
                    }}
                    fullWidth
                    disabled={!isFormValid || !selectedPackageSize || isDimensionExceeded}>
                    <Translation text={'jobs.createJob.generalData.boxDimensionsModal.accept'} />
                </Button>
            }
        />
    );
};

export default BoxSizesModal;
