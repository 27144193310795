import {Typography} from '@mui/material';
import {
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    FormikRegexSettings,
    TextMaskType,
    Translation,
    ValidationErrorType,
    validateNip,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const teamCreateFormConfig = (validTaxIdValue: (value: string | null) => void): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'name',
                    label: t(`teams.teamOperation.formControls.name`),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    type: FormikFieldTypes.CUSTOM,
                    name: 'companyNameText',
                    label: t('teams.teamOperation.formControls.companyName'),
                    validation: Yup.string().notRequired(),
                    customContent: (
                        <div>
                            <Typography color="text.primary" variant="subtitle1" component="h3" sx={{paddingBottom: '2.4rem'}}>
                                <Translation text="teams.teamView.teamDetails.subtitle" />
                            </Typography>
                        </div>
                    ),
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'taxIdentifier',
                    label: t('teams.teamOperation.formControls.taxIdentifier'),
                    validation: Yup.string()
                        .matches(FormikRegexSettings.NIP, `${t(ValidationErrorType.IS_NIP)}`)
                        .test('isNipValid', `${t(ValidationErrorType.IS_NIP)}`, validateNip)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.NIP,
                    isRequired: true,
                    customHandleChange(value) {
                        const isNipValid = validateNip(value);
                        validTaxIdValue(isNipValid ? value : null);
                    },
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'companyName',
                    label: t('teams.teamOperation.formControls.companyName'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },

        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'street',
                    label: t('teams.teamOperation.formControls.street'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'houseNumber',
                    label: t('teams.teamOperation.formControls.houseNumber'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 3,
                    isRequired: true,
                },
                {
                    name: 'flatNumber',
                    label: t('teams.teamOperation.formControls.flatNumber'),
                    validation: Yup.string().max(255),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 3,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'city',
                    label: t('teams.teamOperation.formControls.city'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 3,
                    isRequired: true,
                },
                {
                    name: 'zip',
                    label: t('teams.teamOperation.formControls.zip'),
                    validation: Yup.string()
                        .matches(FormikRegexSettings.POSTAL_CODE, `${t(ValidationErrorType.IS_POST_CODE)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.POSTAL_CODE,
                    controlFlexWidth: 3,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'clearingEmail',
                    label: t('teams.teamOperation.formControls.clearingEmail'),
                    validation: Yup.string()
                        .max(255)
                        .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
    ];
};
