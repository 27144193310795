import {FormikFieldConfig, FormikFieldTypes} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const packageSizeFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'packageSizes',
            label: t('jobs.createJob.generalData.orderCategory'),
            validation: Yup.string(),
            type: FormikFieldTypes.PACKAGE_SIZE,
        },
    ];
};
export default packageSizeFormConfig;
